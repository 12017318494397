import {useEffect} from "react";
import Layouts from "../layouts/Layouts";

import {circleText} from "../common/utilits";
import PageBanner from "../components/PageBanner";
import CallToActionSection from "../components/sections/CallToAction";
import ServiceListen from "../components/sections/ServiceListen";

const ServiceRoute = () => {
    useEffect(() => {
        circleText();
    }, []);

    return (
        <Layouts>
            <PageBanner pageTitle={"Unser Service"} pageDesc={""}/>

            {/* Mindresult Services */}
            <section className="mindresult-section gap-top-50 gap-bottom-140">
                <div className="container">

                    {/* Heading */}
                    <div className="mindresult-heading gap-bottom-40">
                        <div className="mindresult-subtitle-1">
                            <span>Was tun wir?</span>
                        </div>
                        <h2 className="mindresult-title-2">
                            <span>Ihr <span style={{color: '#ff0802'}}>Full-Service</span> Agentur</span>
                        </h2>
                    </div>

                    <>
                        <div className="mindresult-text">
                            <p>Mindresult ist Ihr Full-Service-Partner für alle Bedürfnisse rund um Ihre digitale
                                Präsenz.
                                Wir bieten ein umfassendes Leistungsspektrum, das alle Aspekte von der Konzeption bis
                                zur Optimierung Ihrer Online-Plattform abdeckt.</p>

                            <p>Unser Leistungspaket umfasst professionelles Webdesign, das nicht nur ästhetisch
                                ansprechend ist,
                                sondern auch benutzerfreundlich und technisch auf dem neuesten Stand. Wir entwickeln
                                maßgeschneiderte
                                Websites, die Ihre Marke optimal repräsentieren und eine herausragende Benutzererfahrung
                                bieten.</p>

                            <p>Darüber hinaus kümmern wir uns um die Erstellung hochwertiger Inhalte für Ihre Website,
                                einschließlich Texte,
                                Bilder und Videos. Unsere erfahrenen Autoren und Grafikdesigner arbeiten Hand in Hand,
                                um Inhalte zu
                                erstellen, die Ihre Zielgruppe ansprechen und überzeugen.</p>

                            <div className="gap-top-50 gap-bottom-50">
                                <img src="images/full-service.jpg" alt="full-service"/>
                            </div>

                            <div className="gap-bottom-50">
                                <p>Wir legen großen Wert auf rechtliche Sicherheit und bieten daher die Erstellung von
                                    AGB und
                                    Datenschutzbestimmungen durch unsere erfahrenen Anwälte an. Diese Dokumente werden
                                    speziell auf die
                                    Anforderungen Ihrer Website zugeschnitten und gewährleisten die Einhaltung aller
                                    relevanten gesetzlichen Bestimmungen.</p>

                                <p>Darüber hinaus unterstützen wir Sie bei der Suchmaschinenoptimierung (SEO) Ihrer
                                    Website, um
                                    sicherzustellen, dass Sie online gefunden werden. Unsere SEO-Experten analysieren
                                    Ihre Zielgruppe
                                    und entwickeln eine maßgeschneiderte Strategie, um Ihre Sichtbarkeit in den
                                    Suchergebnissen zu maximieren.</p>

                                <p>Bei Mindresult erhalten Sie einen ganzheitlichen Full-Service, der alle Aspekte Ihrer
                                    digitalen
                                    Präsenz abdeckt und Ihnen dabei hilft, Ihre Ziele im Online-Bereich zu erreichen.
                                    Kontaktieren
                                    Sie uns noch heute, um mehr darüber zu erfahren, wie wir Ihnen helfen können, Ihre
                                    Marke im digitalen
                                    Raum zu stärken und zu etablieren.</p>
                            </div>
                        </div>
                    </>

                    <ServiceListen />

                </div>
            </section>

            <CallToActionSection/>

        </Layouts>
    );
};
export default ServiceRoute;
