import React from 'react';
import './styles/scss/style.scss';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import LayoutRoute from "./routes/LayoutRoute";
import AboutRoute from "./routes/AboutRoute";
import ServiceRoute from "./routes/ServiceRoute";
import DesignAnsatzRoute from "./routes/DesignAnsatzRoute";
import InnovativeLoesungenRoute from "./routes/InnovativeLoesungenRoute";
import ProjektmanagementRoute from "./routes/ProjektmanagementRoute";
import WebdesignRoute from "./routes/WebdesignRoute";
import ResponsiveDesignRoute from "./routes/ResponsiveDesignRoute";
import LogoDesignRoute from "./routes/LogoDesignRoute";
import SeoOptimierungRoute from "./routes/SeoOptimierungRoute";
import WebhostingRoute from "./routes/WebhostingRoute";
import TeamRoute from "./routes/TeamRoute";
import ContactRoute from "./routes/ContactRoute";
import ImpressumRoute from "./routes/ImpressumRoute";
import DatenschutzRoute from "./routes/DatenschutzRoute";
import {createRoot} from "react-dom/client";
import E404 from "./routes/404";

const router = createBrowserRouter([
    {
        path: "/", element: <LayoutRoute />,
    },
    {
        path: "about", element: <AboutRoute />,
    },
    {
        path: "services", element: <ServiceRoute />,
    },
    {
        path: "services/design-ansatz", element: <DesignAnsatzRoute />,
    },
    {
        path: "services/innovative-loesungen", element: <InnovativeLoesungenRoute />,
    },
    {
        path: "services/projektmanagement", element: <ProjektmanagementRoute />,
    },
    {
        path: "services/webdesign", element: <WebdesignRoute />,
    },
    {
        path: "services/responsive-design", element: <ResponsiveDesignRoute />,
    },
    {
        path: "services/logo-design", element: <LogoDesignRoute />,
    },
    {
        path: "services/seo-optimierung", element: <SeoOptimierungRoute />,
    },
    {
        path: "services/webhosting", element: <WebhostingRoute />,
    },
    {
        path: "team", element: <TeamRoute />,
    },
    {
        path: "contact", element: <ContactRoute />,
    },
    {
        path: "impressum", element: <ImpressumRoute />,
    },
    {
        path: "datenschutz", element: <DatenschutzRoute />,
    },
    {
        path: "*", element: <E404 />
    }
]);

window.sessionStorage.clear();

createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
