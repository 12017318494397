import Layouts from "../layouts/Layouts";
import PageBanner from "../components/PageBanner";

import RightMenu from "../components/sections/RightMenu";

const InnovativeLoesungenRoute = ( ) => {

    return (
        <Layouts>
            <PageBanner pageTitle="Innovative Lösungen" pageDesc={""} />

            <section className="mindresult-section gap-top-60 gap-bottom-40">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">

                            {/* Image */}
                            <div className="gap-bottom-50">
                                <img src="/images/innoloesungen.jpg" alt="Innovative Lösungen" />
                            </div>
                            {/* Title */}
                            <div className="mindresult-text">
                                <h3>Innovative Lösungen</h3>
                            </div>
                            <div className="mindresult-text">
                                <p>Bei Mindresult betrachten wir Innovation nicht nur als Trend, sondern als Kernprinzip
                                    unserer Arbeitsweise. Unser Ansatz für innovative Lösungen zeichnet sich durch folgende Merkmale aus:</p>

                                <p>Proaktives Denken: Wir sind nicht nur reaktiv, sondern proaktiv in unserer Herangehensweise
                                    an innovative Lösungen. Durch fortlaufende Forschung und Entwicklung bleiben wir stets
                                    auf dem neuesten Stand, um Ihnen zukunftsweisende Lösungen bieten zu können.</p>

                                <p>Individuelle Anpassung: Jedes Unternehmen ist einzigartig, und so sollten auch die
                                    Lösungen sein. Unser Team entwickelt maßgeschneiderte, innovative Lösungen, die
                                    speziell auf Ihre individuellen Anforderungen zugeschnitten sind.</p>

                                <p>Technologische Vielseitigkeit: Wir setzen auf eine breite Palette von Technologien,
                                    um sicherzustellen, dass Ihre Lösung nicht nur aktuell, sondern auch flexibel und
                                    skalierbar ist. Unser technologisches Arsenal ermöglicht es uns, innovative Ideen
                                    in die Realität umzusetzen.</p>

                                <p>Agile Methoden: Innovation erfordert Flexibilität. Unser Einsatz agiler Methoden
                                    ermöglicht es uns, schnell auf Veränderungen zu reagieren und kontinuierlich
                                    Verbesserungen vorzunehmen, um sicherzustellen, dass Ihre Lösung stets auf dem
                                    neuesten Stand ist.</p>

                                <p>Fokus auf Mehrwert: Unsere innovativen Lösungen sind darauf ausgerichtet, nicht nur
                                    technologischen Fortschritt zu bieten, sondern auch einen klaren Mehrwert für Ihr
                                    Unternehmen zu schaffen. Wir denken strategisch und langfristig, um sicherzustellen,
                                    dass Ihre Investition nachhaltigen Nutzen bringt. Mit unserem Engagement für Innovation
                                    streben wir danach, nicht nur Probleme zu lösen, sondern auch neue Chancen zu schaffen.</p>

                                <p>Lassen Sie uns gemeinsam Ihre Herausforderungen angehen und innovative Lösungen finden, die Ihr Unternehmen vorantreiben.</p>
                            </div>


                        </div>
                        <RightMenu />
                    </div>
                </div>
            </section>
        </Layouts>
    );
};
export default InnovativeLoesungenRoute;

