import Layouts from "../layouts/Layouts";
import PageBanner from "../components/PageBanner";

import Accordion from "react-bootstrap/Accordion";
import RightMenu from "../components/sections/RightMenu";

const WebdesignRoute = ( ) => {

    return (
        <Layouts>
            <PageBanner pageTitle="Webdesign" pageDesc={""} />

            <section className="mindresult-section gap-top-60 gap-bottom-40">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">

                            {/* Image */}
                            <div className="gap-bottom-50">
                                <img src="/images/webdesign-mittel.jpg" alt="Webdesign" />
                            </div>
                            {/* Title */}
                            <div className="mindresult-text">
                                <h3>Webdesign</h3>
                            </div>
                            <div className="mindresult-text">
                                <p>Träumen Sie von einer Website, die nicht nur Ihre Vision, sondern auch Ihre Einzigartigkeit
                                    widerspiegelt? Unser engagiertes Team versteht die Individualität Ihrer Marke und kreiert
                                    einen maßgeschneiderten Web-Auftritt, der nicht nur ästhetisch ansprechend ist, sondern
                                    auch Ihre Identität authentisch präsentiert.</p>

                                <p>Von der Konzeptentwicklung über das Design bis hin zur Umsetzung, wir begleiten Sie
                                    auf Ihrer digitalen Reise. Jeder Pixel wird sorgfältig platziert, um sicherzustellen,
                                    dass Ihre Botschaft klar und überzeugend übermittelt wird.</p>

                                <p>Lassen Sie uns gemeinsam Ihre Online-Präsenz optimieren und die Grenzen des Möglichen
                                    überschreiten. Kontaktieren Sie uns noch heute und entdecken Sie, wie wir Ihren
                                    digitalen Raum gestalten können. Wir freuen uns darauf, Ihre einzigartige Geschichte
                                    zu erzählen und Ihr Online-Potenzial zu entfesseln.</p>
                            </div>
                            <div className="gap-top-40">
                                <h3>Was Sie von uns erwarten können</h3>
                                <p>Möchten Sie einen professionellen, ansprechenden Webauftritt, der Ihre Marke optimal repräsentiert und Besucher in Kunden verwandelt? Unsere individuellen Webdesign- und Entwicklungsleistungen bieten die ideale Lösung.</p>
                                <p>Verleihen Sie Ihrer Online-Präsenz den entscheidenden Vorsprung mit einem herausragenden Webdesign und einer technisch ausgefeilten Entwicklung. Kontaktieren Sie uns für eine umfassende Beratung zu Ihren individuellen Anforderungen.</p>


                                {/* Faq items */}
                                <Accordion>
                                    <div className="mindresult-faq-items">
                                        <Accordion.Item key={`faq-item-1`} eventKey={`faq-acc-1`}>
                                            <div className="mindresult-faq-item mindresult-collapse-item">
                                                <Accordion.Header>
                                                    <h5 className="title mindresult-collapse-btn">
                                                        <span>Alles aus einer Hand</span>
                                                        <i className="arrow" />
                                                    </h5>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="mindresult-text">
                                                        Von modernsten, technisch anspruchsvollen Webseiten bis hin
                                                        zu lizenzierten Bildern und Videos - alles ist bereits im Preis
                                                        enthalten. Wir verstehen, dass Ihre Online-Präsenz mehr ist als
                                                        nur ein hübsches Design. Deshalb bieten wir Ihnen nicht nur
                                                        die ästhetische Hülle, sondern auch den inneren Wert.
                                                    </div>
                                                </Accordion.Body>
                                            </div>
                                        </Accordion.Item>
                                        <Accordion.Item key={`faq-item-2`} eventKey={`faq-acc-2`}>
                                            <div className="mindresult-faq-item mindresult-collapse-item">
                                                <Accordion.Header>
                                                    <h5 className="title mindresult-collapse-btn">
                                                        <span>Unsere Texte sind keine Standard-Phrasen</span>
                                                        <i className="arrow" />
                                                    </h5>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="mindresult-text">
                                                        Wir haben für Sie erfahrene Autoren, die Inhalte schaffen, die
                                                        nicht nur informieren, sondern begeistern. Zusätzlich kümmern sich
                                                        unsere Anwälte um die rechtliche Sicherheit Ihrer Webseite.
                                                        Maßgeschneiderte AGB und Datenschutzbestimmungen, die speziell
                                                        für Ihr Unternehmen angepasst werden, geben Ihnen die Gewissheit,
                                                        rechtlich auf der sicheren Seite zu stehen.
                                                    </div>
                                                </Accordion.Body>
                                            </div>
                                        </Accordion.Item>
                                        <Accordion.Item key={`faq-item-3`} eventKey={`faq-acc-3`}>
                                            <div className="mindresult-faq-item mindresult-collapse-item">
                                                <Accordion.Header>
                                                    <h5 className="title mindresult-collapse-btn">
                                                        <span>Suchmaschinenoptimierung (SEO)</span>
                                                        <i className="arrow" />
                                                    </h5>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="mindresult-text">
                                                        Unsere Experten kümmern sich auch um die Suchmaschinenoptimierung (SEO)
                                                        Ihrer Webseite. Damit werden Sie nicht nur online gefunden, sondern auch
                                                        von potenziellen Kunden wahrgenommen. Wir machen Ihre Webseite sichtbar
                                                        und bringen Sie in die Spitzenpositionen der Suchergebnisse.
                                                    </div>
                                                </Accordion.Body>
                                            </div>
                                        </Accordion.Item>
                                        <Accordion.Item key={`faq-item-4`} eventKey={`faq-acc-4`}>
                                            <div className="mindresult-faq-item mindresult-collapse-item">
                                                <Accordion.Header>
                                                    <h5 className="title mindresult-collapse-btn">
                                                        <span>Kreative Gestaltungsprinzipien für visuelle Exzellenz</span>
                                                        <i className="arrow" />
                                                    </h5>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="mindresult-text">
                                                        Unsere Experten verwenden innovative Designansätze, um eine ästhetisch
                                                        ansprechende Website zu gestalten, die nicht nur den aktuellen Trends
                                                        entspricht, sondern auch Ihre Markenidentität wirkungsvoll kommuniziert.
                                                    </div>
                                                </Accordion.Body>
                                            </div>
                                        </Accordion.Item>
                                        <Accordion.Item key={`faq-item-5`} eventKey={`faq-acc-5`}>
                                            <div className="mindresult-faq-item mindresult-collapse-item">
                                                <Accordion.Header>
                                                    <h5 className="title mindresult-collapse-btn">
                                                        <span>Benutzerfreundlichkeit für optimale Nutzererfahrung</span>
                                                        <i className="arrow" />
                                                    </h5>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="mindresult-text">
                                                        Wir legen Wert auf eine intuitive Benutzeroberfläche und eine nahtlose
                                                        Navigation, um sicherzustellen, dass Ihre Besucher eine positive und
                                                        effiziente Erfahrung auf Ihrer Website machen.
                                                    </div>
                                                </Accordion.Body>
                                            </div>
                                        </Accordion.Item>
                                        <Accordion.Item key={`faq-item-6`} eventKey={`faq-acc-6`}>
                                            <div className="mindresult-faq-item mindresult-collapse-item">
                                                <Accordion.Header>
                                                    <h5 className="title mindresult-collapse-btn">
                                                        <span>Technische Umsetzung auf höchstem Niveau</span>
                                                        <i className="arrow" />
                                                    </h5>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="mindresult-text">
                                                        Von responsivem Design über schnelle Ladezeiten bis hin zur Integration
                                                        neuester Technologien – wir garantieren eine technisch einwandfreie Umsetzung,
                                                        die Ihre Website leistungsstark und zukunftsfähig macht.
                                                    </div>
                                                </Accordion.Body>
                                            </div>
                                        </Accordion.Item>
                                        <Accordion.Item key={`faq-item-7`} eventKey={`faq-acc-7`}>
                                            <div className="mindresult-faq-item mindresult-collapse-item">
                                                <Accordion.Header>
                                                    <h5 className="title mindresult-collapse-btn">
                                                        <span>Responsivität für maximale Erreichbarkeit</span>
                                                        <i className="arrow" />
                                                    </h5>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="mindresult-text">
                                                        Unsere Websites passen sich verschiedenen Geräten und Bildschirmgrößen
                                                        an, um sicherzustellen, dass Sie Ihre Zielgruppe auf Desktops, Tablets
                                                        und Smartphones optimal erreichen.
                                                    </div>
                                                </Accordion.Body>
                                            </div>
                                        </Accordion.Item>
                                    </div>
                                </Accordion>
                            </div>


                        </div>
                        <RightMenu />
                    </div>
                </div>
            </section>
        </Layouts>
    );
};
export default WebdesignRoute;

