import {Typography} from "@mui/material";

const Datenschutzes = ({ services, introHidden, noPaddingBottom }) => {
    return (
        <>
            <section className="mindresult-section gap-top-50 gap-bottom-100">
                <div className="container-xxl project py-5">
                    <div className="container">
                        <Typography variant="h2" gutterBottom>Datenschutzerklärung</Typography>
                        <div className="mindresult-text">
                            <p>Wir freuen uns über Ihr Interesse an unserem Unternehmen. Der Schutz Ihrer personenbezogenen
                                Daten ist uns ein wichtiges Anliegen. Nachstehend informieren wir Sie ausführlich über den Umgang mit Ihren Daten.</p>
                            <h4>1. Name und Kontaktdaten des Verantwortlichen</h4>

                            <p>Verantwortlich für die Datenverarbeitung auf dieser Website im Sinne der Datenschutz-Grundverordnung (DSGVO) ist:<br />
                                <br />mindresult Gbr
                                <br />Peter-Anders-Str. 42
                                <br />81245 München
                                <br />Deutschland
                                <br />Tel: 0155 - 6663 1607
                                <br />E-Mail: info@mindresult.de
                            </p>
                            <br />
                            <h4>2. Erfassung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</h4>

                            <h5>a) Beim Besuch der Website</h5>

                        <div>Beim Aufrufen unserer Website werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser
                            automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden
                            temporär in einem sogenannten Logfile gespeichert. Folgende Informationen werden dabei ohne
                            Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:</div>

                            <ul>
                                <li>IP-Adresse des anfragenden Rechners</li>
                                <li>Datum und Uhrzeit des Zugriffs</li>
                                <li>Name und URL der abgerufenen Datei</li>
                                <li>Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
                                <li>verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers</li>
                            </ul>

                            <div>Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:</div>
                            <ul>
                                <li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Website</li>
                                <li>Gewährleistung einer komfortablen Nutzung unserer Website</li>
                                <li>Auswertung der Systemsicherheit und -stabilität</li>
                                <li>sowie zu weiteren administrativen Zwecken</li>
                            </ul>

                            <div>
                                Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.
                                Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung.
                                In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre
                                Person zu ziehen.</div>

                            <div>
                                <p>Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie Analysedienste ein.
                                    Nähere Erläuterungen dazu erhalten Sie unter den Ziff. 4 und 5 dieser Datenschutzerklärung.</p>
                            </div>

                            <h5>b) Bei Nutzung unseres Kontaktformulars</h5>

                            <div>Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über ein auf der
                                Website bereitgestelltes Formular Kontakt aufzunehmen. Dabei ist die Angabe einer gültigen
                                E-Mail-Adresse erforderlich, damit wir wissen, von wem die Anfrage stammt und um diese
                                beantworten zu können. Weitere Angaben können freiwillig getätigt werden.</div>

                            <div>
                                <p>Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig erteilten Einwilligung.</p>

                                <p>Die für die Benutzung des Kontaktformulars von uns erhobenen personenbezogenen Daten werden nach Erledigung der von Ihnen gestellten Anfrage automatisch gelöscht.</p>
                            </div>

                            <h4>3. Weitergabe von Daten</h4>

                            <div>
                                Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.

                                <p>Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</p>
                                <ul>
                                    <li>Sie Ihre ausdrückliche Einwilligung dazu erteilt haben</li>
                                    <li>die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich ist</li>
                                    <li>die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist</li>
                                    <li>die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und kein
                                        Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse
                                        an der Nichtweitergabe Ihrer Daten haben</li>
                                </ul>
                            </div>
                            <h4>4. Cookies</h4>
                            <p>Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die
                                Ihr Browser automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.)
                                gespeichert werden, wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät
                                keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.</p>

                            <p>In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem
                                spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch
                                unmittelbar Kenntnis von Ihrer Identität erhalten.</p>

                            <p>Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie
                                angenehmer zu gestalten. So setzen wir sogenannte Session-Cookies ein, um zu erkennen,
                                dass Sie einzelne Seiten unserer Website bereits besucht haben. Diese werden nach
                                Verlassen unserer Seite automatisch gelöscht.</p>

                            <p>Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre
                                Cookies ein, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert
                                werden. Besuchen Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen,
                                wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen
                                sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.</p>

                            <p>Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer
                                berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.</p>

                            <p>Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch
                                so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets
                                ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollständige Deaktivierung
                                von Cookies kann jedoch dazu führen, dass Sie nicht alle Funktionen unserer Website nutzen können.</p>

                            <h4>5. Analyse-Tools</h4>

                            <h5>a) Tracking-Tools</h5>

                            <p>Die im Folgenden aufgeführten und von uns eingesetzten Tracking-Maßnahmen werden auf
                                Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO durchgeführt. Mit den zum Einsatz kommenden
                                Tracking-Maßnahmen wollen wir eine bedarfsgerechte Gestaltung und die fortlaufende
                                Optimierung unserer Webseite sicherstellen. Zum anderen setzen wir die Tracking-Maßnahmen
                                ein, um die Nutzung unserer Webseite statistisch zu erfassen und zum Zwecke der Optimierung
                                unseres Angebotes für Sie auszuwerten. Diese Interessen sind als berechtigt im Sinne
                                der vorgenannten Vorschrift anzusehen.</p>

                            <h5>b) Google Analytics</h5>

                            <p>Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden Optimierung unserer Seiten
                                nutzen wir Google Analytics, ein Webanalysedienst der Google Inc. (https://www.google.de/intl/de/about/)
                                (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; im Folgenden „Google“).
                                In diesem Zusammenhang werden pseudonymisierte Nutzungsprofile erstellt und Cookies
                                (siehe unter Ziff. 4) verwendet. Die durch den Cookie erzeugten Informationen über
                                Ihre Benutzung dieser Website wie</p>
                            <ul>
                                <li>Browser-Typ/-Version</li>
                                <li>verwendetes Betriebssystem</li>
                                <li>Referrer-URL (die zuvor besuchte Seite)</li>
                                <li>Hostname des zugreifenden Rechners (IP-Adresse)</li>
                                <li>Uhrzeit der Serveranfrage</li>
                            </ul>

                            <p>werden an einen Server von Google in den USA übertragen und dort gespeichert. Die
                                Informationen werden verwendet, um die Nutzung der Website auszuwerten, um Reports
                                über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung
                                und der Internetnutzung verbundene Dienstleistungen zu Zwecken der Marktforschung und
                                bedarfsgerechten Gestaltung dieser Internetseiten zu erbringen. Auch werden diese Informationen
                                gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit
                                Dritte diese Daten im Auftrag verarbeiten. Es wird in keinem Fall Ihre IP-Adresse mit
                                anderen Daten von Google zusammengeführt. Die IP-Adressen werden anonymisiert, so dass
                                eine Zuordnung nicht möglich ist (IP-Masking).</p>

                            <p>Sie können die Installation der Cookies durch eine entsprechende Einstellung der
                                Browser-Software verhindern; wir weisen jedoch darauf hin, dass in diesem Fall
                                gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich genutzt werden können.</p>

                            <p>Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre
                                Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung
                                dieser Daten durch Google verhindern, indem Sie ein Browser-Add-on herunterladen und
                                installieren (<a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a>).</p>

                            <p>Als Alternative zum Browser-Add-on, insbesondere bei Browsern auf mobilen Endgeräten,
                                können Sie die Erfassung durch Google Analytics zudem verhindern, indem Sie auf diesen
                                Link klicken. Es wird ein Opt-out-Cookie gesetzt, das die zukünftige Erfassung Ihrer
                                Daten beim Besuch dieser Website verhindert. Der Opt-out-Cookie gilt nur in diesem
                                Browser und nur für unsere Website und wird auf Ihrem Gerät abgelegt. Löschen Sie die
                                Cookies in diesem Browser, müssen Sie das Opt-out-Cookie erneut setzen.</p>

                            <p>Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie
                                etwa in der Google Analytics-Hilfe (<a href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank">https://support.google.com/analytics/answer/6004245?hl=de</a> ).</p>

                            <h4>6. Betroffenenrechte</h4>

                            <p>Sie haben das Recht:</p>

                            <li>gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen.
                                Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten,
                                die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante
                                Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung
                                oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht
                                bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich
                                Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;</li>
                            <li>gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns
                                gespeicherten personenbezogenen Daten zu verlangen;</li>
                            <li>gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen,
                                soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information,
                                zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur
                                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;</li>
                            <li>gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen,
                                soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist,
                                Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur
                                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO
                                Widerspruch gegen die Verarbeitung eingelegt haben;</li>
                            <li>gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem
                                strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung
                                an einen anderen Verantwortlichen zu verlangen;</li>
                            <li>gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu
                                widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung
                                beruhte, für die Zukunft nicht mehr fortführen dürfen und</li>
                            <li>gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie
                                sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes
                                oder unseres Unternehmenssitzes wenden.</li>
                            <br />
                            <h4>7. Widerspruchsrecht</h4>

                            <p>Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen
                                gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO
                                Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe
                                vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen
                                Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das
                                ohne Angabe einer besonderen Situation von uns umgesetzt wird.</p>

                            <p>Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an info@mindresult.de.</p>

                            <h4>8. Datensicherheit</h4>

                            <p>Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer)
                                in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt
                                wird. In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser
                                keine 256-Bit Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie
                                zurück. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird,
                                erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols
                                in der unteren Statusleiste Ihres Browsers.</p>

                            <p>Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen,
                                um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen
                                Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere
                                Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.</p>

                            <h4>9. Aktualität und Änderung dieser Datenschutzerklärung</h4>

                            <p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand 14. März 2024.</p>

                            <p>Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter
                                gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese
                                Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit
                                auf der Website unter https://www.mindresult.de/datenschutz von Ihnen abgerufen und ausgedruckt werden.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Datenschutzes;
