import PageBanner from "../components/PageBanner";
import Layouts from "../layouts/Layouts";

import CallToActionSection from "../components/sections/CallToAction";
import Accordion from 'react-bootstrap/Accordion';
import {Typography} from "@mui/material";
import {experience} from "../common/staticTexte";


const TeamRoute = () => {
    return (
        <Layouts>
            <PageBanner pageTitle={"Unser Team"} />

            {/* Mindresult Team */}
            <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
                <div className="row g-0 mx-lg-0">
                    <div className="col-lg-6 ps-lg-0">
                        <div className="position-relative h-100">
                            <img className="position-absolute img-fluid w-100 h-100" src="images/team2.jpg" style={{ objectFit: 'cover'}} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 py-1">
                        <div className="p-lg-5 pe-lg-5">
                            <h5 style={{ color: '#ff0802'}} >Unser Team</h5>
                            <h1 className="mb-4">Warum Sie sich auf unser Team verlassen k&ouml;nnen</h1>
                            <p>Unser engagiertes und kompetentes Team von Experten, mit 20 Jahren Erfahrung, bringt nicht nur fundierte Kenntnisse, sondern geht auch leidenschaftlich auf die individuellen W&uuml;nsche unserer Kunden ein.
                            </p>
                            <br />
                            <Typography variant="h4">Erfahrungen:</Typography>
                            <br/>
                            <Accordion>
                                <div className="mindresult-faq-items">
                                    {experience.map((item, key) => (
                                        <Accordion.Item key={`faq-item-${key}`} eventKey={`faq-acc-${key}`}>
                                            <div className="mindresult-faq-item mindresult-collapse-item">
                                                <Accordion.Header>
                                                    <h5 className="title mindresult-collapse-btn">
                                                        <span>{item.title}</span>
                                                        <i className="arrow" />
                                                    </h5>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="mindresult-text">{item.text}</div>
                                                </Accordion.Body>
                                            </div>
                                        </Accordion.Item>
                                    ))}
                                </div>
                            </Accordion>

                            <p>Entdecken Sie die Kraft eines kompetenten Teams, das nicht nur Ihre technischen Anforderungen erf&uuml;llt, sondern auch Ihre kreativen Visionen
                                in die Realit&auml;t umsetzt. Wir freuen uns darauf, mit Ihnen gemeinsam digitale Meisterwerke zu schaffen.</p>
                        </div>
                    </div>
                </div>
            </div>

            <CallToActionSection />

        </Layouts>
    );
};
export default TeamRoute;

