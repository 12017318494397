import PageBanner from "../components/PageBanner";
import Layouts from "../layouts/Layouts";

import { Formik } from 'formik';
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import {Link} from "react-router-dom";

const Contact = () => {

    return (
        <Layouts>
            <PageBanner pageTitle={"Kontakt"} />

            {/* Mindresult Contact Info */}
            <section className="mindresult-section gap-top-140 gap-bottom-60">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">

                            {/* Heading */}
                            <div className="mindresult-text gap-bottom-40">
                                <h4>Vereinbaren Sie noch heute einen Termin mit uns</h4>
                                Schreiben Sie uns jetzt eine Nachricht und vereinbaren Sie ein Erstgespräch für Ihren Webauftritt im Internet.
                            </div>

                            {/* Form */}
                            <div className="mindresult-form">
                                <Formik
                                    initialValues = {{ email: '', name: '', tel: '', message: '' }}
                                    validate = { values => {
                                        const errors = {};
                                        if (!values.email) {
                                            errors.email = 'Required';
                                        } else if (
                                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                        ) {
                                            errors.email = 'Ungültige Email Addresse';
                                        }
                                        return errors;
                                    }}
                                    onSubmit = {( values, { setSubmitting } ) => {
                                        const form = document.getElementById("contactForm");
                                        const status = document.getElementById("contactFormStatus");
                                        const data = new FormData();

                                        data.append('name', values.name);
                                        data.append('tel', values.tel);
                                        data.append('email', values.email);
                                        data.append('message', values.message);

                                        fetch(form.action, {
                                            method: 'POST',
                                            body: data,
                                            headers: {
                                                'Accept': 'application/json'
                                            }
                                        }).then(response => {
                                            if (response.ok) {
                                                status.innerHTML = "Danke für Ihre Anfrage!";
                                                form.reset()
                                            } else {
                                                response.json().then(data => {
                                                    if (Object.hasOwn(data, 'errors')) {
                                                        status.innerHTML = data["errors"].map(error => error["message"]).join(", ")
                                                    } else {
                                                        status.innerHTML = "Oops! Da ist etwas schiefgelaufen!"
                                                    }
                                                })
                                            }
                                        }).catch(error => {
                                            status.innerHTML = "Oops! Da ist etwas schiefgelaufen!"
                                        });

                                        setSubmitting(false);
                                    }}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          isSubmitting,
                                          /* and other goodies */
                                      }) => (
                                        <form onSubmit={handleSubmit} id="contactForm" action="https://formspree.io/f/mqkrebjb" className="cform" method="post">
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <p>
                                                        <input
                                                            placeholder="Ihr Name"
                                                            type="text"
                                                            name="name"
                                                            required="required"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.name}
                                                        />
                                                    </p>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <p>
                                                        <input
                                                            placeholder="Ihre Email Addresse"
                                                            type="email"
                                                            name="email"
                                                            required="required"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.email}
                                                        />
                                                    </p>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <p>
                                                        <input
                                                            placeholder="Telefon Nummer"
                                                            type="tel"
                                                            name="tel"
                                                            required="required"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.tel}
                                                        />
                                                    </p>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <p>
                                            <textarea
                                                placeholder="Ihre Nachricht"
                                                name="message"
                                                required="required"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.message}
                                            />
                                                    </p>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <p>
                                                        <button type="submit" className="mindresult-btn mindresult-hover-btn">
                                                            <span>Anfrage Absenden</span>
                                                        </button>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="form-status alert-success" id="contactFormStatus" />
                                        </form>
                                    )}
                                </Formik>
                            </div>

                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">

                            {/* Contact Info */}
                            <div className="mindresult-contact-info mindresult-text-white">
                                <ul>
                                    <li>
                                        <h5>Kontakt Infos</h5>
                                        <p>
                                            <Link className="mindresult-lnk lnk--active" to="tel:+4915566631607" target="_blank">
                                                <PhoneOutlinedIcon />
                                                <span> +49 (0) 89 2894 2883</span>
                                            </Link>
                                        </p>
                                        <p>
                                            <Link className="mindresult-lnk lnk--active" to="tel:+4915566631607" target="_blank">
                                                <SmartphoneIcon />
                                                <span> +49 (0) 155 6663 1607</span>
                                            </Link>
                                        </p>
                                        <p>
                                            <Link className="mindresult-lnk lnk--active" to="mailto:info@mindresult.de" target="_blank">
                                                <EmailOutlinedIcon />
                                                <span> info@mindresult.de</span>
                                            </Link>
                                        </p>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </Layouts>
    );
};
export default Contact;
