import {Link} from "react-router-dom";
import {Formik} from "formik";
import {serviceList} from "../../common/staticTexte";

const RightMenu = () => {
    const onClickEffect = () => {
        window.scroll({top: 0, left: 0, behavior: "smooth"});
    };

    return (
        <>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                {/* Service menu */}
                <div className="mindresult-service-item mindresult-service-no-icon gap-bottom-40">
                    <div className="mindresult-service-item-inner mindresult-hover-3 mindresult-hover-black">
                        <h5 className="title">
                            <span data-splitting data-mindresult-scroll> Unsere Service </span>
                        </h5>
                        <div className="list">
                            <ul>
                                {serviceList.map((item, key) => (
                                    <li key={`services-item-${key}`}>
                                        <Link className="mindresult-lnk" to={item.url} onClick={onClickEffect}>
                                            <span data-splitting data-mindresult-scroll>{item.title}</span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Mindresult Form */}
                <div className="mindresult-form-box mindresult-text-white">
                    <h5>Kontaktieren Sie uns</h5>
                    <p>Schreiben Sie uns jetzt eine Nachricht und vereinbaren Sie ein Erstgespräch für Ihren Webauftritt im Internet!</p>
                    <Formik
                        initialValues = {{ email: '', name: '', tel: '', message: '' }}
                        validate = { values => {
                            const errors = {};
                            if (!values.email) {
                                errors.email = 'Required';
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                                errors.email = 'Ungültige Email Adresse';
                            }
                            return errors;
                        }}
                        onSubmit = {( values, { setSubmitting } ) => {
                            const form = document.getElementById("contactForm");
                            const status = document.getElementById("contactFormStatus");
                            const data = new FormData();

                            data.append('name', values.name);
                            data.append('tel', values.tel);
                            data.append('email', values.email);
                            data.append('message', values.message);

                            fetch(form.action, {
                                method: 'POST',
                                body: data,
                                headers: {
                                    'Accept': 'application/json'
                                }
                            }).then(response => {
                                if (response.ok) {
                                    status.innerHTML = "Vielen Dank für Ihre Anfrage!";
                                    form.reset()
                                } else {
                                    response.json().then(data => {
                                        if (Object.hasOwn(data, 'errors')) {
                                            status.innerHTML = data["errors"].map(error => error["message"]).join(", ")
                                        } else {
                                            status.innerHTML = "Oops! Da ist etwas schiefgelaufen!"
                                        }
                                    })
                                }
                            }).catch(error => {
                                status.innerHTML = "Oops! Da ist etwas schiefgelaufen!"
                            });

                            setSubmitting(false);
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              /* and other goodies */
                          }) => (
                            <form onSubmit={handleSubmit} id="contactForm" action="https://formspree.io/f/mqkrebjb" className="cform" method="post">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <p>
                                            <input
                                                size="40"
                                                placeholder="Ihr Name"
                                                type="text"
                                                name="name"
                                                required="required"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name}
                                            />
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <p>
                                            <input
                                                size="40"
                                                placeholder="Ihre Email Addresse"
                                                type="email"
                                                name="email"
                                                required="required"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                            />
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <p>
                                            <input
                                                size="40"
                                                placeholder="Telefonnummer"
                                                type="tel"
                                                name="tel"
                                                required="required"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.tel}
                                            />
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <p>
											<textarea
                                                cols="40"
                                                rows="10"
                                                placeholder="Ihre Nachricht"
                                                name="message"
                                                required="required"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.message}
                                            />
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <p>
                                            <button type="submit" className="mindresult-btn mindresult-hover-btn btn--active2">
                                                <span>Anfrage Absenden</span>
                                            </button>
                                        </p>
                                    </div>
                                </div>

                                <div className="form-status alert-success" id="contactFormStatus" />
                            </form>
                        )}
                    </Formik>
                </div>

            </div>
        </>
    );
};

export default RightMenu;
