import {useEffect, useState} from "react";
import {Box, Dialog} from "@mui/material";
import {Link} from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

const Popup = () => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const popupSession = window.sessionStorage.getItem('popup');
        setTimeout(() => {
            if (!popupSession) {
                setOpen(true);
                window.sessionStorage.setItem('popup', true);
            }
        }, 7000);
    },[]);

    const handleClose = () => {
        setOpen(false);
    };

    const onClickEffect = () => {
        window.scroll({top: 0, left: 0, behavior: "smooth"});
    };

    return (
        <div className="wow fadeIn animated"data-wow-delay="10s">
            <Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="modal mindresult-modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="row g-0">
                                <div className="col-12">
                                    <div className="pe-md-0 pb-0 pb-md-2">
                                        <img src="/images/responsive-mittel.jpg" width="100%" className="rounded-3 img-fluid" />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="modal-body h-100">
                                        <div className="d-flex flex-column justify-content-between h-100">
                                            <div className="flex-shrink-0">
                                                <h3 className="fw-semibold" style={{ paddingTop: '15px', color: '#ff0802'}}>60% RABATT AKTION!</h3>
                                                <span className="text-secondary mb-3">
                                                    Für alle Neukunden, gibt es für den Monat April
                                                    <span style={{ color: '#ff0802'}}><b> 60% RABATT </b></span> auf kompletten Webdesign mit Full-Service.
                                                    <p>Handeln Sie schnell und nehmen Sie noch heute Kontakt mit uns auf!</p>
                                                </span>
                                            </div>
                                            <div className="d-flex gap-2">
                                                <Link className="mindresult-btn mindresult-hover-btn" to="/contact" onClick={onClickEffect}>
                                                    <span>Kontakt</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="close-btn">
                                <CloseIcon onClick={handleClose}/>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Box>
        </div>
    );
}

export default Popup;
