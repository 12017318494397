import { Link } from "react-router-dom";

const AboutSection = () => {
    return (
      <>
        {/* Mindresult About */}
        <section className="mindresult-section gap-top-140 gap-bottom-140">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">

                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-8 col-lg-12">

                    {/* Heading */}
                    <div className="mindresult-heading gap-bottom-40">
                      <h2 className="mindresult-title-2">
                        <span>Unser <b style={{ color: '#ff0802'}}>Passion</b> für Design <br/>und Entwicklung </span>
                      </h2>
                    </div>

                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-12 hide-on-desktop gap-bottom-60">

                    {/* Number */}
                    <div className="mindresult-number mindresult-circle-text mrg-left">
                      <div className="num mindresult-text-white">
                        <span>20</span>
                      </div>
                      <div className="label mindresult-text-black mindresult-circle-text-label">
                        * Jahre Erfahrung in Design und Entwicklung
                      </div>
                    </div>

                  </div>
                </div>

                {/* Description */}
                <div className="row">
                  <div key={`about-item-1`} className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <h5 className="text-uppercase">Unsere Mission</h5>
                    <p>Unser engagiertes Team von Experten bringt nicht nur fundierte Kenntnisse in den Bereichen
                      Webdesign,Webentwicklung, Responsive Design, Logo Design, SEO-Optimierung und Webhosting mit,
                      sondern geht auch leidenschaftlich auf die individuellen Wünsche unserer Kunden ein.
                      <br /><br />
                      Diese breite Palette von Fähigkeiten ermöglicht es uns, umfassende Lösungen anzubieten, die den gesamten digitalen Lebenszyklus abdecken.</p>
                  </div>
                  <div key={`about-item-2`} className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <h5 className="text-uppercase">Unser Ziel</h5>
                    <p>Bei Mindresult sind wir leidenschaftlich darauf bedacht, nicht nur Ergebnisse zu liefern, sondern
                      Exzellenz in jedem Projekt zu erreichen. Unser Team setzt sich dafür ein, Ihre Erwartungen zu
                      übertreffen und beeindruckende digitale Erlebnisse zu schaffen.</p>

                      <Link className="mindresult-btn mindresult-hover-btn" to="/about">
                        <i className="arrow"><span /></i>
                        <span>Mehr über uns</span>
                      </Link>

                  </div>
                </div>

              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 hide-on-mobile">

                {/* Number */}
                <div className="mindresult-number mindresult-circle-text mrg-right">
                  <div className="num mindresult-text-white">
                    <span>20</span>
                  </div>
                  <div className="label mindresult-text-black mindresult-circle-text-label">
                    * Jahre Erfahrung in Design und Entwicklung
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </>
    );
};

export default AboutSection;
