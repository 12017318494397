import 'splitting/dist/splitting.css'
import 'splitting/dist/splitting-cells.css'

const HeroSection = () => {

	const video = [
		'/video/qualm.mp4',
		'/video/abstract1.mp4'
	];

	function getRandomInt() {
		return Math.floor(Math.random() * 2);
	}

	function getVideo(x) {
		return video[x];
	}

    return (
        <>
            {/* Mindresult Hero */}
			<section className="mindresult-section mindresult-hero">
				<div className="image">
					<video autoPlay muted={true} loop playsInline id="heroVideo">
						<source src={getVideo(getRandomInt())} type="video/mp4" />
					</video>
					<div className="ovrl" style={{"opacity": "0.85"}} />
				</div>
				<div className="container">
					<h1 className="title mindresult-text-white">
						<span data-splitting data-mindresult-scroll>
							<img className="logo--white" src="/images/logo-big.png" alt="mindresult logo" />
						</span>
						<span className="title word">
						</span>
					</h1>
					<div className="text">
						<div className="subtitle mindresult-text-white">
							<div data-splitting data-mindresult-scroll>
								Bringen Sie Ihre Marke zum Strahlen.<br/>Mit uns werden Ihre digitale Präsenz zum Meisterwek.
                            </div>
						</div>
					</div>
				</div>
			</section>
        </>
    );
};

export default HeroSection;
