import PageBanner from "../components/PageBanner";
import Layouts from "../layouts/Layouts";

import Impressums from '../components/sections/Impressuns'

const ImpressumRoute = () => {
    return (
        <Layouts>
            <PageBanner pageTitle={"Impressum"} />

            <Impressums />

        </Layouts>
    );
};
export default ImpressumRoute;
