import Layouts from "../layouts/Layouts";
import PageBanner from "../components/PageBanner";

import RightMenu from "../components/sections/RightMenu";

const WebhostingRoute = ( ) => {

    return (
        <Layouts>
            <PageBanner pageTitle="Webhosting" pageDesc={""} />

            <section className="mindresult-section gap-top-60 gap-bottom-40">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">

                            {/* Image */}
                            <div className="gap-bottom-50">
                                <img src="/images/webhosting-mittel.jpg" alt="Webhosting" />
                            </div>
                            {/* Title */}
                            <div className="mindresult-text">
                                <h3>Webhosting</h3>
                            </div>
                            <div className="mindresult-text">
                                <p>Ihr digitaler Raum verdient einen zuverlässigen Gastgeber. Wir verstehen, dass Webhosting
                                    mehr ist als nur Speicherplatz, es ist die Grundlage für Ihre Online-Präsenz. Unser
                                    engagiertes Team kümmert sich nicht nur um die Einrichtung und Verwaltung Ihres Hostings,
                                    sondern bietet auch eine persönliche Betreuung, die weit über die technischen Aspekte hinausgeht.</p>

                                <p>Von der Auswahl der richtigen Hosting-Option bis zur kontinuierlichen Administration
                                    stehen wir Ihnen zur Seite. Ihre Website soll nicht nur online sein, sondern auch
                                    ein nahtloses und sicheres Erlebnis bieten. Vertrauen Sie uns, um sicherzustellen,
                                    dass Ihr digitaler Raum immer optimal funktioniert.</p>

                                <p>Kontaktieren Sie uns, um mehr darüber zu erfahren, wie wir Ihr Hosting personalisiert und
                                    professionell betreuen können. Machen Sie Hosting zu einem stressfreien Teil Ihres digitalen Erfolgs.</p>
                            </div>


                        </div>
                        <RightMenu />
                    </div>
                </div>
            </section>
        </Layouts>
    );
};
export default WebhostingRoute;

