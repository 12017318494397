import { useEffect } from "react";
import Layouts from "../layouts/Layouts";


import { circleText } from "../common/utilits";
import ServiceListSection from "../components/sections/ServiceList";
import PageBanner from "../components/PageBanner";
import ServiceListen from "../components/sections/ServiceListen";

const AboutRoute = () => {
    useEffect(() => {
        circleText();
    }, []);

    return (
        <Layouts>
            <PageBanner pageTitle={"Über uns"} />

            {/* Mindresult About */}
            <section className="mindresult-section gap-top-140 gap-bottom-140">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                            {/* Heading */}
                            <div className="mindresult-heading gap-bottom-60">
                                <h2 className="mindresult-title-2">
                                    <span>Unser <b style={{ color: '#ff0802'}}>Passion</b> für Design <br/>und Entwicklung </span>
                                </h2>
                                <div className="mindresult-text">
                                    <p><strong>Unser Versprechen:</strong> Wir bieten nicht nur herausragende Designs, sondern ein umfassendes Rundum-sorglos-Paket, das Ihre Online-Präsenz zum strahlenden Erfolg macht.</p>
                                </div>
                            </div>
                        </div>
                        {/* image */}
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 offset-lg-1 hide-on-mobile">
                            <img src="/images/logo.png" alt="" />
                        </div>
                    </div>

                    {/* was machen wir */}
                    <div className="row gap-top-30">
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-2">
                            <h5 className="text-uppercase">Wer sind wir?</h5>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9">
                            Mindresult Ihr kreativer Digitalagentur für maßgeschneiderte Lösungen! Als Experten für Webdesign,
                            Webentwicklung, Responsive Design, Logo Design, SEO-Optimierung und Webhosting sind wir leidenschaftlich daran,
                            Ihre digitale Präsenz zu formen und zu optimieren.
                        </div>
                    </div>

                    <div className="row gap-top-40">
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-2">
                            <h5 className="text-uppercase">Unser Vision</h5>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9">
                            In der sich ständig wandelnden digitalen Landschaft streben wir danach, Innovation und Ästhetik zu
                            verbinden. Wir glauben daran, dass jedes Unternehmen eine einzigartige digitale Identität verdient,
                            die nicht nur visuell ansprechend ist, sondern auch strategisch und funktional.
                        </div>
                    </div>

                    <div className="row gap-top-40">
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-2">
                            <h5 className="text-uppercase">Unsere Werte</h5>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9">
                            Bei Mindresult glauben wir an Innovation, Kreativität und Kundenzufriedenheit. Diese Werte bilden
                            das Fundament unserer Arbeit. Wir streben ständig nach neuen Ideen, um Ihren digitalen Auftritt auf
                            das nächste Level zu heben. Unsere Kreativität kennt keine Grenzen, und die Zufriedenheit unserer
                            Kunden ist der Maßstab für unseren Erfolg.
                        </div>
                    </div>

                    <div className="row gap-top-40">
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-2">
                            <h5 className="text-uppercase">Unser Team</h5>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9">
                            Hinter jedem erfolgreichen Projekt steht ein engagiertes Team von Fachleuten. Unser vielfältiges
                            Team aus Designern, Entwicklern, Autoren und Anwälten bringt ein breites Spektrum an Fähigkeiten
                            und Perspektiven mit. Gemeinsam arbeiten wir Hand in Hand, um die besten Ergebnisse für unsere Kunden zu erzielen.
                        </div>
                    </div>

                    <div className="row gap-top-40">
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-2">
                            <h5 className="text-uppercase">Unser Ansatz</h5>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9">
                            Wir verstehen, dass Ihr Erfolg untrennbar mit unserem eigenen verbunden ist. Daher gehen wir weit
                            über die Erwartungen hinaus, um sicherzustellen, dass jedes Detail perfekt ist. Unser Ansatz ist nicht
                            nur professionell, sondern auch partnerschaftlich. Bei Mindresult sind Sie nicht nur Kunde, sondern Teil
                            einer kreativen Gemeinschaft, die nach Exzellenz strebt.
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                        {/* Heading */}
                        <div className="mindresult-heading gap-top-60">
                            <div className="mindresult-text">
                                <p>Erfahren Sie mehr darüber, wie <b style={{ color: '#ff0802'}}>Mindresult</b> Ihre digitale Präsenz neu definieren kann. Wir freuen uns darauf, gemeinsam mit Ihnen eine inspirierende und erfolgreiche Online-Reise zu beginnen!"</p>
                            </div>
                        </div>
                    </div>

                    {/* Gallery */}
                    <div className="row gap-top-100">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <img src="/images/team1.jpg" alt="" />
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 gap-top-60">
                            <img src="/images/team2.jpg" alt="" />
                        </div>
                    </div>

                </div>
            </section>

            <ServiceListen />

        </Layouts>
    );
};
export default AboutRoute;
