import {useEffect} from "react";
import {footerSticky} from "../common/utilits";
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import {Link} from "react-router-dom";

const Footer = () => {
    useEffect(() => {
        footerSticky();
    }, []);

    const onClickEffect = () => {
        window.scroll({top: 0, left: 0, behavior: "smooth"});
    };

    return (
        <>
            {/* Footer */}
            <footer className="mindresult-footer footer--dark">
                <div className="footer--default">
                    <div className="container">
                        <div className="row gap-bottom-40">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-2">

                                {/* Logo */}
                                <div className="mindresult-f-logo gap-bottom-40" style={{"maxWidth": "130px"}}>
                                    <Link to="/" className="mindresult-no-text-decoration" onClick={onClickEffect}>
                                        <img src="/images/logo.png" alt="mindresult logo"/>
                                    </Link>
                                </div>

                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">

                                {/* Description */}
                                <div className="mindresult-text mindresult-text-white">
                                    Wir helfen Ihnen erfolgreich Ihr Webauftritt zu machen <br/><strong>Sprechen Sie mit
                                    uns!</strong>
                                </div>

                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">

                                {/* Description */}
                                <div className="mindresult-text mindresult-text-white">
                                    <ul className="mindresult-footer-menu">
                                        <li>
                                            <Link to="/services/webdesign" className="mindresult-lnk-black lnk--active" onClick={onClickEffect}>
                                                <span className="mindresult-lnk">Webdesign</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services/responsive-design" className="mindresult-lnk-black lnk--active" onClick={onClickEffect}>
                                                <span className="mindresult-lnk">Responsive Design</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services/logo-design" className="mindresult-lnk-black lnk--active" onClick={onClickEffect}>
                                                <span className="mindresult-lnk">Logo Design</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services/seo-optimierung" className="mindresult-lnk-black lnk--active" onClick={onClickEffect}>
                                                <span className="mindresult-lnk">SEO Optimierung</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services/webhosting" className="mindresult-lnk-black lnk--active" onClick={onClickEffect}>
                                                <span className="mindresult-lnk">Webhosting</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">

                                {/* Description */}
                                <div className="mindresult-text mindresult-text-white">
                                    Schreiben Sie uns, oder rufen Sie uns an!<br/>
                                    <p>
                                        <Link className="mindresult-no-text-decoration btn--icon"
                                              to="tel:+4915566631607" target="_blank">
                                            <PhoneOutlinedIcon/>
                                            <span> +49 (0) 89 2894 2883</span>
                                        </Link>
                                    </p>
                                    <p>
                                        <Link className="mindresult-no-text-decoration btn--icon"
                                              to="tel:+4915566631607" target="_blank">
                                            <SmartphoneIcon/>
                                            <span> +49 (0) 155 6663 1607</span>
                                        </Link>
                                    </p>
                                    <p>
                                        <Link className="mindresult-no-text-decoration btn--icon"
                                              to="mailto:info@mindresult.de" target="_blank">
                                            <EmailOutlinedIcon/>
                                            <span> info@mindresult.de</span>
                                        </Link>
                                    </p>
                                </div>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 align-self-center">
                                <div className="copyright mindresult-text-white">
                                    <span style={{fontSize: '12px'}}>© 2023 <a to='https://mindresult.de' target='blank'
                                                                               className='mindresult-lnk-black lnk--active'>mindresult </a>. All rights reserved.</span>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 align-right">
                                <div className="copyright mindresult-text-white">
                                    <Link to="/impressum" className="mindresult-lnk-black lnk--active" onClick={onClickEffect}>
                                        <span style={{fontSize: '12px'}} className="mindresult-lnk">Impressum</span>
                                    </Link>
                                    <span style={{fontSize: '12px'}} > | </span>
                                    <Link to="/datenschutz" className="mindresult-lnk-black lnk--active" onClick={onClickEffect}>
                                        <span style={{fontSize: '12px'}} className="mindresult-lnk">Datenschutzerklärung</span>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </footer>
        </>
    );
};
export default Footer;
