import {useState} from "react";
import {Link} from "react-router-dom";

const Header = () => {
    const [desktopMenu, desktopMenuToggle] = useState(false);

    const clickedDesktopMenu = (e) => {
        e.preventDefault();
        desktopMenuToggle(!desktopMenu);

        const menuPopup = document.querySelector('.mindresult-menu-popup');
        const menuContainer = document.querySelector('.mindresult-menu-container');
        const menuBody = document.querySelector('body');
        const menuHeader = document.querySelector('.mindresult-header');
        const menuButton = document.querySelector('.mindresult-menu-btn');

        if (desktopMenu) {
            menuBody.classList.remove('mindresult--noscroll');
            menuHeader.classList.remove('header--active');
            menuPopup.classList.remove('menu--ready');
            menuContainer.classList.add('mindresult--noscroll');
            menuButton.parentNode.classList.add('mindresult--notouch');
            let timer1 = setTimeout(function () {
                menuPopup.classList.remove('menu--open');
            }, 300);
            let timer2 = setTimeout(function () {
                menuButton.parentNode.classList.remove('mindresult--notouch');
                menuPopup.classList.remove('menu--visible');
            }, 1600);
        } else {
            menuBody.classList.add('mindresult--noscroll');
            menuHeader.classList.add('header--active');
            menuPopup.classList.add('menu--visible');
            menuPopup.classList.add('menu--open');
            menuButton.parentNode.classList.add('mindresult--notouch');
            let timer3 = setTimeout(function () {
                menuButton.parentNode.classList.remove('mindresult--notouch');
                menuContainer.classList.remove('mindresult--noscroll');
                menuPopup.classList.add('menu--ready');
            }, 600);
        }

    }
    const clickedMobileMenuItemParent = (e) => {
        e.preventDefault();
        e.target.parentNode.classList.toggle('opened');
    }

    const onClickEffect = () => {
        window.scroll({top: 0, left: 0, behavior: "smooth"});
    };

    return (
        <>
            {/* Header */}
            <header className="mindresult-header header--white sticky">
                <div className="header--builder">
                    <div className="container">
                        <div className="row">
                            <div className="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-3 align-self-center">

                                {/* Logo */}
                                <div>
                                    <div style={{display: 'flex'}}>
                                        <Link to="/">
                                            <img className="logo--white" src="/images/logo-symbol-text-black.png"
                                                 alt="mindresult logo"/>
                                            <div className="logo-text hide-on-mobile-extra">
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                            </div>
                            <div
                                className="col-8 col-xs-8 col-sm-8 col-md-4 col-lg-6 align-self-center align-center m-align-right">

                                {/* Menu Horizontal */}
                                <div className="mindresult-menu-horizontal">
                                    <ul className="mindresult-menu-nav">
                                        <li key={`header-nav-item-1`} className="dropdown-link">
                                            <Link className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                  to="/" onClick={onClickEffect}>Home</Link>
                                        </li>
                                        <li key={`header-nav-item-2`} className="dropdown-link">
                                            <Link className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                  to="/about" onClick={onClickEffect}>Über uns</Link>
                                        </li>
                                        <li key={`header-nav-item-3`} className="dropdown-link menu-item-has-children">
                                            <Link className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                  to="/services" onClick={onClickEffect}>Service</Link>
                                            <i className="icon fas fa-chevron-down"/>
                                            <ul className="sub-menu">
                                                <li key={`header-nav-sub-item-1`}>
                                                    <Link
                                                        className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                        to="/services/design-ansatz" onClick={onClickEffect}>Design Ansatz</Link>
                                                </li>
                                                <li key={`header-nav-sub-item-2`}>
                                                    <Link
                                                        className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                        to="/services/innovative-loesungen" onClick={onClickEffect}>Innovative Lösungen</Link>
                                                </li>
                                                <li key={`header-nav-sub-item-3`}>
                                                    <Link
                                                        className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                        to="/services/projektmanagement" onClick={onClickEffect}>Projektmanagement</Link>
                                                </li>
                                                <li key={`header-nav-sub-item-4`}>
                                                    <Link
                                                        className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                        to="/services/webdesign" onClick={onClickEffect}>Webdesign</Link>
                                                </li>
                                                <li key={`header-nav-sub-item-5`}>
                                                    <Link
                                                        className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                        to="/services/responsive-design" onClick={onClickEffect}>Responsive Design</Link>
                                                </li>
                                                <li key={`header-nav-sub-item-6`}>
                                                    <Link
                                                        className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                        to="/services/logo-design" onClick={onClickEffect}>Logo Design</Link>
                                                </li>
                                                <li key={`header-nav-sub-item-7`}>
                                                    <Link
                                                        className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                        to="/services/seo-optimierung" onClick={onClickEffect}>SEO Optimierung</Link>
                                                </li>
                                                <li key={`header-nav-sub-item-8`}>
                                                    <Link
                                                        className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                        to="/services/webhosting" onClick={onClickEffect}>Webhosting</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li key={`header-nav-item-4`} className="dropdown-link">
                                            <Link className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                  to="/team" onClick={onClickEffect}>Unser Team</Link>
                                        </li>

                                    </ul>
                                </div>

                                {/* Menu Hamburger */}
                                <Link to="./layouts/Header2#"
                                      className={desktopMenu ? "mindresult-menu-btn btn--active" : "mindresult-menu-btn"}
                                      onClick={(e) => clickedDesktopMenu(e)}><span/></Link>
                                <div className="mindresult-menu-popup align-left">
                                    <div className="mindresult-menu-overlay"></div>
                                    <div className="mindresult-menu-overlay-after"></div>
                                    <div className="mindresult-menu-container mindresult--noscroll">
                                        <div className="container">
                                            <div className="mindresult-menu">
                                                <ul className="mindresult-menu-nav">
                                                    <li key={`header-nav-item-1`} className="dropdown-link">
                                                        <Link
                                                            className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                            to="/" onClick={onClickEffect}>Home</Link>
                                                    </li>
                                                    <li key={`header-nav-item-2`} className="dropdown-link">
                                                        <Link
                                                            className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                            to="/about" onClick={onClickEffect}>Über uns</Link>
                                                    </li>
                                                    <li key={`header-nav-item-3`}
                                                        className="dropdown-link menu-item-has-children">
                                                        <Link
                                                            className="mindresult-no-text-decoration mindresult-lnk lnk--active mindresult-dropdown-toggle"
                                                            onClick={(e) => clickedMobileMenuItemParent(e)}
                                                            to="/services">Service</Link>
                                                        <i className="icon fas fa-chevron-down"/>
                                                        <ul className="sub-menu">
                                                            <li key={`header-nav-sub-item-1`}>
                                                                <Link style={{fontSize: '16px'}}
                                                                      className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                                      to="/services/design-ansatz" onClick={onClickEffect}>Design Ansatz</Link>
                                                            </li>
                                                            <li key={`header-nav-sub-item-2`}>
                                                                <Link style={{fontSize: '16px'}}
                                                                      className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                                      to="/services/innovative-loesungen" onClick={onClickEffect}>Innovative
                                                                    Lösungen</Link>
                                                            </li>
                                                            <li key={`header-nav-sub-item-3`}>
                                                                <Link style={{fontSize: '16px'}}
                                                                      className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                                      to="/services/projektmanagement" onClick={onClickEffect}>Projektmanagement</Link>
                                                            </li>
                                                            <li key={`header-nav-sub-item-4`}>
                                                                <Link style={{fontSize: '16px'}}
                                                                      className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                                      to="/services/webdesign" onClick={onClickEffect}>Webdesign</Link>
                                                            </li>
                                                            <li key={`header-nav-sub-item-5`}>
                                                                <Link style={{fontSize: '16px'}}
                                                                      className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                                      to="/services/responsive-design" onClick={onClickEffect}>Responsive
                                                                    Design</Link>
                                                            </li>
                                                            <li key={`header-nav-sub-item-6`}>
                                                                <Link style={{fontSize: '16px'}}
                                                                      className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                                      to="/services/logo-design" onClick={onClickEffect}>Logo Design</Link>
                                                            </li>
                                                            <li key={`header-nav-sub-item-7`}>
                                                                <Link style={{fontSize: '16px'}}
                                                                      className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                                      to="/services/seo-optimierung" onClick={onClickEffect}>SEO
                                                                    Optimierung</Link>
                                                            </li>
                                                            <li key={`header-nav-sub-item-8`}>
                                                                <Link style={{fontSize: '16px'}}
                                                                      className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                                      to="/services/webhosting" onClick={onClickEffect}>Webhosting</Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li key={`header-nav-item-4`} className="dropdown-link">
                                                        <Link
                                                            className="mindresult-no-text-decoration mindresult-lnk lnk--active "
                                                            to="/team" onClick={onClickEffect}>Unser Team</Link>
                                                    </li>
                                                    <li key={`header-nav-sub-item-4`}>
                                                        <Link className="mindresult-no-text-decoration mindresult-lnk lnk--active"
                                                            to="/contact" onClick={onClickEffect}>Kontakt</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div
                                className="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-3 align-self-center align-right hide-on-mobile-extra">

                                {/* Button */}
                                <Link
                                    className="mindresult-no-text-decoration mindresult-btn mindresult-hover-btn btn--active2"
                                    to="/contact" onClick={onClickEffect}>
                                    <span>Kontakt</span>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};
export default Header;
