import {Link} from "react-router-dom";

const PageBanner = ({pageTitle}) => {
	const pathname = window.location.pathname;

    const onClickEffect = () => {
        window.scroll({top: 0, left: 0, behavior: "smooth"});
    };

    return (
        <>
            {/* Mindresult Intro */}
            <section className="mindresult-section mindresult-intro intro--black">
                <div className="container">
                    <h1 className="mindresult-title-1  mindresult-text-white">
                        <span>{pageTitle}</span>
                        <span className="mindresult-sep word">
						<div className="cta-img-circle img-circle--page-banner"/>
					</span>
                    </h1>
                    <div className="mindresult-breadcrums">
                        <ul>
                            <li>
                                <Link to="/" onClick={onClickEffect}>Home</Link>
                            </li>
                            {pathname.indexOf('/services/') != -1 &&
                                <li>
                                    <Link to="/services" onClick={onClickEffect}>Services</Link>
                                </li>
                            }
                            <li className="current">
                                <span>{pageTitle}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
};
export default PageBanner;
