import ServiceListen from "./ServiceListen";

const ServiceListSection = ({ noPaddingBottom }) => {

    return(
        <>
            <section className={noPaddingBottom ? "mindresult-section mindresult-section-bg gap-top-40" : "mindresult-section mindresult-section-bg gap-top-40 gap-bottom-140"}>
                <div className="container wow fadeInLeft" data-wow-delay="0.9s" data-splitting data-mindresult-scroll >
                    <ServiceListen />
                </div>
            </section>
        </>
    );
}

export default ServiceListSection;
