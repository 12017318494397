import {Box, Typography} from "@mui/material";

const Impressums = ({ services, introHidden, noPaddingBottom }) => {
    return (
        <>
            <section className="mindresult-section gap-top-50 gap-bottom-100">
                <div className="container-xxl project py-5">
                    <div className="container">
                        <Typography variant="h2" gutterBottom>Impressum</Typography>
                        <br /><h3>Angaben gemäß § 5 TMG</h3>
                        <br />mindresult Gbr
                        <br />Peter-Anders-Str. 42
                        <br />81245 München
                        <p></p>
                        <br /><b>Kontakt:</b>
                        <br />0155 6663 1607
                        <br />info@mindresult.de
                        <p></p>

                        <br />Unternehmen in Gründung
                        <br />
                        <br />Geschäftsführung:
                        <br />Osman Direk
                        <br />
                        <br />Inhaltlich Verantwortlicher gem. &sect; 55 Abs. 2 RStV:
                        <br />Osman Direk, Peter-Anders-Str. 42, 81245 München
                        <br />
                        <br />
                        <p><b>EU-Streitschlichtung</b></p>
                        <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr.</p>
                        <p>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

                        <p><b>Verbraucherstreitbeilegung/Universalschlichtungsstelle</b></p>
                        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                        <p><b>Haftung für Inhalte</b></p>
                        <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                            verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte
                            oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
                            Tätigkeit hinweisen.</p>

                        <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
                            bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
                            einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden
                            wir diese Inhalte umgehend entfernen.</p>

                        <p><b>Urheberrecht</b></p>
                        <p> Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht.
                            Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
                            bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
                            sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>

                        <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet.
                            Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
                            aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                            werden wir derartige Inhalte umgehend entfernen.</p>
                        <p><b>Quellenangaben für die verwendeten und lizensierten Bilder und Grafiken</b></p>
                        <p>envato elements, shutterstock, istockphoto</p>
                    </div>
                </div>
            </section>
    </>
                );
}

export default Impressums;
