import SmartphoneIcon from '@mui/icons-material/Smartphone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { Link } from "react-router-dom";

const CallToActionSection = () => {
  return (
    <>
        {/* Mindresult CTA */}
        <section className="mindresult-section gap-top-50 gap-bottom-0" style={{"backgroundColor": "black", "backgroundImage": "url(/images/contact.jpg)", "backgroundPosition": "center bottom", "backgroundRepeat": "no-repeat", "backgroundSize": "cover"}}>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">

                        {/* Heading */}
                        <div className="mindresult-heading gap-bottom-40 mindresult-text-white">
                            <div className="mindresult-subtitle-1">
                                <span>Mehr Informationen?</span>
                            </div>
                            <h2 className="mindresult-title-2">
                                <span>Senden Sie uns eine <br/><strong>Nachricht</strong></span>
                            </h2>
                        </div>

                        {/* Text */}
                        <div className="mindresult-cta-text">
                            <p>Wir freuen uns<br />von Ihnen zu hören.</p>
                        </div>

                        <div className="mindresult-cta-text">
                            <p>
                                <Link className="mindresult-no-text-decoration btn--icon" href="tel:+4915566631607" target="_blank">
                                    <PhoneOutlinedIcon />
                                    <span className="mindresult-text-white"> +49 (0) 89 2894 2883</span>
                                </Link>
                            </p>
                            <p>
                                <Link className="mindresult-no-text-decoration btn--icon" href="tel:+4915566631607" target="_blank">
                                    <SmartphoneIcon />
                                    <span className="mindresult-text-white"> +49 (0) 155 6663 1607</span>
                                </Link>
                            </p>
                            <p>
                                <Link className="mindresult-no-text-decoration btn--icon" href="mailto:info@mindresult.de" target="_blank">
                                    <EmailOutlinedIcon />
                                    <span className="mindresult-text-white"> info@mindresult.de</span>
                                </Link>
                            </p>
                        </div>

                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                        <div className="desc">
                            <ul>
                                <li key={`cta-social-item-1`}>
                                    <Link className="mindresult-no-text-decoration mindresult-btn mindresult-hover-btn btn--active2 btn--icon btn--large" to="/contact" target="_self">
                                        <i aria-hidden="true" className="fa fa-envelope" />
                                        <span>Kontakt</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
};

export default CallToActionSection;
