import React from "react";
import { useEffect } from "react";
import Layouts from "../layouts/Layouts";


import { circleText } from "../common/utilits";
import AboutSection from "../components/sections/About";
import CallToActionSection from "../components/sections/CallToAction";
import WelcomeTextSection from "../components/sections/WelcomeText";
import ServiceListSection from "../components/sections/ServiceList";
import HeroSection from "../components/sections/Hero";
import PopupInfoDialog from "../components/sections/Popup";

const LayoutRoute = () => {
    useEffect(() => {
        circleText();
    }, []);

    return (
        <Layouts header={2} footer={2}>
            <>
                <HeroSection />
                <WelcomeTextSection />
                <AboutSection />
                <ServiceListSection />
                <CallToActionSection />
                <PopupInfoDialog />
            </>
        </Layouts>
    );
};
export default LayoutRoute;
