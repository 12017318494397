import Layouts from "../layouts/Layouts";
import PageBanner from "../components/PageBanner";

import RightMenu from "../components/sections/RightMenu";

const LogoDesignRoute = ( ) => {

    return (
        <Layouts>
            <PageBanner pageTitle="Logo Design" pageDesc={""} />

            <section className="mindresult-section gap-top-60 gap-bottom-40">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">

                            {/* Image */}
                            <div className="gap-bottom-50">
                                <img src="/images/logo-mittel.jpg" alt="Logo Design" />
                            </div>
                            {/* Title */}
                            <div className="mindresult-text">
                                <h3>Logo Design</h3>
                            </div>
                            <div className="mindresult-text">
                                <p>Ihr Unternehmen verdient ein Logo, das nicht nur auffällt, sondern auch Ihre
                                    Einzigartigkeit unterstreicht. Unser engagiertes Logo-Design-Team versteht, dass
                                    Ihr Markenimage mehr ist als nur ein visuelles Element, es ist die Essenz Ihrer Identität.</p>

                                <p>Von der ersten Skizze bis zur finalen Umsetzung setzen wir uns dafür ein, ein Logo zu
                                    schaffen, das nicht nur ästhetisch ansprechend ist, sondern auch Ihre Werte und Vision
                                    verkörpert. Jeder Farbton, jede Linie und jedes Detail werden sorgfältig durchdacht,
                                    um sicherzustellen, dass Ihr Logo nicht nur ins Auge fällt, sondern auch eine tiefere
                                    Bedeutung transportiert.</p>

                                <p>Lassen Sie uns gemeinsam das Gesicht Ihrer Marke formen und ein Logo kreieren,
                                    das nicht nur zeitlos ist, sondern auch einen bleibenden Eindruck hinterlässt.</p>

                                <p>Kontaktieren Sie uns, um die Reise zu beginnen, Ihr Markenimage zu stärken und sich von der Masse abzuheben.</p>
                            </div>
                            <div className="gap-top-40 gap-bottom-40">
                                <h3>Was Sie von erwarten können</h3>
                                <p>Logo Design wird durch die Kombination von kreativem Denken, Markenidentitätserfassung, klarem visuellem Konzept und einfacher Wiedererkennbarkeit umgesetzt.</p>
                                <ul>
                                    <li>
                                        Kreatives Denken.
                                    </li>
                                    <li>
                                        Erfassung der Markenidentität.
                                    </li>
                                    <li>
                                        Klare visuelle Konzeption.
                                    </li>
                                    <li>
                                        Einfache Wiedererkennbarkeit.
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <RightMenu />
                    </div>
                </div>
            </section>
        </Layouts>
    );
};
export default LogoDesignRoute;

