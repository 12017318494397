import Layouts from "../layouts/Layouts";
import PageBanner from "../components/PageBanner";
import Datenschutzes from "../components/sections/Datenschutzes";

const DatenschutzRoute = () => {
    return (
        <Layouts>
            <PageBanner pageTitle={"Datenschutz"} />

            <Datenschutzes />

        </Layouts>
    );
};
export default DatenschutzRoute;
