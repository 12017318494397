import {Link} from "react-router-dom";
import {serviceList} from "../../common/staticTexte";

const ServiceListen = () => {

    const onClickEffect = () => {
        window.scroll({top: 0, left: 0, behavior: "smooth"});
    };

    return (
        <>
            <section className="mindresult-section gap-top-40 gap-bottom-60">
                <div className="container wow fadeInLeft" data-wow-delay="0.9s" data-splitting data-mindresult-scroll >
                    <div className="text-center mx-auto mb-5" style={{minHeight: 50}}>
                        <h3 style={{ color: '#ff0802'}}>Unser Service</h3>
                    </div>
                    <div className="row g-4" data-wow-delay="9s">
                        {serviceList.map((item, key) => (
                            <div key={`services2-item-${key}`}  className="col-md-6 col-lg-4" data-wow-delay="0.1s">
                                <div className="service-item rounded overflow-hidden wow fadeInLeft" data-wow-delay="9s" data-mindresult-scroll >
                                    <Link to={item.url} className="mindresult-no-text-decoration" onClick={onClickEffect}>
                                        <img src={item.img} height="237px" width="100%" alt={item.title} />
                                        <div className="position-relative p-4 pt-0">
                                            <div className="service-icon">
                                                <img src={item.icon} alt={item.title} style={{ height: "60px" }}/>
                                            </div>
                                            <h5 className="text-uppercase">{item.title}</h5>
                                            <span>{item.short}</span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServiceListen;
