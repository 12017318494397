import Layouts from "../layouts/Layouts";
import PageBanner from "../components/PageBanner";

import RightMenu from "../components/sections/RightMenu";

const ResponsiveDesignRoute = ( ) => {

    return (
        <Layouts>
            <PageBanner pageTitle="Responsive Design" pageDesc={""} />

            <section className="mindresult-section gap-top-60 gap-bottom-40">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">

                            {/* Image */}
                            <div className="gap-bottom-50">
                                <img src="/images/responsive-mittel.jpg" alt="Responsive Design" />
                            </div>
                            {/* Title */}
                            <div className="mindresult-text">
                                <h3>Responsive Design</h3>
                            </div>
                            <div className="mindresult-text">
                                <p>In der digitalen ära ist Flexibilität entscheidend. Ihr Publikum ist über verschiedene
                                    Geräte und Bildschirmgrößen verteilt, und wir verstehen, dass Ihre Website auf jedem
                                    davon brillant aussehen sollte. Unser engagiertes Responsive-Design-Team sorgt dafür,
                                    dass Ihre Online-Präsenz auf jedem Bildschirm, sei es Desktop, Tablet oder Smartphone,
                                    beeindruckt.</p>

                                <p>Wir gehen über die bloße Anpassung hinaus, wir schaffen eine konsistente und ansprechende
                                    Benutzererfahrung, unabhängig von der Plattform. Jeder Klick sollte nahtlos sein,
                                    und jedes Detail sollte auf jedem Gerät perfekt zur Geltung kommen.</p>

                                <p>Kontaktieren Sie uns, um die Zukunft Ihrer Website zu besprechen. Entdecken Sie, wie
                                    Responsive Design nicht nur Ihre Sichtbarkeit steigert, sondern auch das Engagement
                                    und die Zufriedenheit Ihrer Nutzer erhöht.</p>
                            </div>

                            <h3>Was Sie von uns erwarten können</h3>
                            <p>Responsive Design basiert auf flexiblen Layouts, Medienabfragen, flexiblen Bildern und
                                dem Einsatz von Fluid Grids, um sicherzustellen, dass eine Website auf verschiedenen
                                Geräten und Bildschirmgrößen optimal dargestellt wird.</p>
                            <ul>
                                <li>
                                    Flexible Layouts
                                </li>
                                <li>
                                    Flexible Bilder
                                </li>
                                <li>
                                    Optimiert für PC-, Tablet- und Handy-Ansichten
                                </li>
                            </ul>
                        </div>
                        <RightMenu />
                    </div>
                </div>
            </section>
        </Layouts>
    );
};
export default ResponsiveDesignRoute;

