import Layouts from "../layouts/Layouts";
import PageBanner from "../components/PageBanner";

import RightMenu from "../components/sections/RightMenu";

const DesignAnsatzRoute = ( ) => {

    return (
        <Layouts>
            <PageBanner pageTitle="Design Ansatz" pageDesc={""} />

            <section className="mindresult-section gap-top-60 gap-bottom-40">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">

                            {/* Image */}
                            <div className="gap-bottom-50">
                                <img src="/images/designansatz.jpg" alt="Design Ansatz" />
                            </div>
                            {/* Title */}
                            <div className="mindresult-text">
                                <h3>Design Ansatz</h3>
                            </div>
                            <div className="mindresult-text">
                                <p>In der Welt des Designs hebt sich Mindresult durch einen einzigartigen Ansatz hervor,
                                    der auf Innovation, Kreativität und strategischer Vision basiert. Unser Design-Ansatz
                                    zeichnet sich durch folgende Merkmale aus:</p>

                                <p>Ästhetik mit Bedeutung: Jedes Designelement wird sorgfältig ausgewählt, um nicht nur
                                    ästhetisch ansprechend zu sein, sondern auch eine tiefere Bedeutung und Relevanz
                                    für Ihre Marke zu transportieren.</p>

                                <p>Benutzerzentrierter Fokus: Unser Design-Ansatz beruht auf einer starken Benutzerorientierung.
                                    Wir verstehen die Bedürfnisse und Erwartungen Ihrer Zielgruppe, um ein Design zu schaffen,
                                    das nicht nur schön ist, sondern auch effektiv kommuniziert.</p>

                                <p>Innovatives Denken: Wir gehen über das Gewöhnliche hinaus und setzen auf innovative
                                    Designlösungen. Unser Team denkt kreativ, um sicherzustellen, dass Ihr Design nicht
                                    nur im aktuellen Umfeld, sondern auch in der Zukunft relevant bleibt.</p>

                                <p>Markenidentität im Fokus: Ihr Design repräsentiert nicht nur Ihre Website, sondern
                                    Ihre gesamte Markenidentität. Wir legen großen Wert darauf, dass das Design harmonisch
                                    mit Ihrer Markenpersönlichkeit verschmilzt und einen konsistenten visuellen Ausdruck schafft.</p>

                                <p>Kollaborativer Prozess: Bei Mindresult ist Design ein kollaborativer Prozess. Wir arbeiten
                                    eng mit Ihnen zusammen, um sicherzustellen, dass Ihre Vision und Ideen in das
                                    endgültige Design integriert werden, sodass das Ergebnis eine authentische
                                    Darstellung Ihres Unternehmens ist. Mit unserem einzigartigen Design-Ansatz
                                    streben wir danach, nicht nur visuell ansprechende, sondern auch bedeutungsvolle
                                    und wirkungsvolle Designs zu schaffen.</p>

                                <p>Lassen Sie uns gemeinsam Ihre Marke durch beeindruckendes Design zum Leben erwecken.</p>
                            </div>


                        </div>
                        <RightMenu />
                    </div>
                </div>
            </section>
        </Layouts>
    );
};
export default DesignAnsatzRoute;

