export const serviceList = [
    {
    img: '/images/designansatz.jpg',
        url: '/services/design-ansatz',
        title: 'Design Ansatz',
        icon: '/images/icon-designansatz.png',
        short: 'Ästhetik mit Bedeutung, Benutzerzentrierter Fokus, Innovatives Denken, Markenidentität, Kollaborativer Prozess.'
    },
    {
        img: '/images/innoloesungen.jpg',
        url: '/services/innovative-loesungen',
        title: 'Innovative Lösungen',
        icon: '/images/serv-icon6.png',
        short: 'Proaktives Denken, Individuelle Anpassung, Technologische Vielseitigkeit, Agile Methoden, Fokus auf Mehrwert.'
    },
    {
        img: '/images/projektmanagement.jpg',
        url: '/services/projektmanagement',
        title: 'Projektmanagement',
        icon: '/images/icon-projectmanagement.png',
        short: 'Kreative Herangehensweise, Transparente Kommunikation, Strategische Planung, Flexibilität und Anpassungsfähigkeit'
    },
    {
        img: '/images/webdesign-mittel.jpg',
        url: '/services/webdesign',
        title: 'Webdesign',
        icon: '/images/icon-webdesign.png',
        short: 'Eine Website, die nicht nur Ihre Vision, sondern auch Ihre Einzigartigkeit widerspiegelt!'
    },
    {
        img: '/images/responsive-mittel.jpg',
        url: '/services/responsive-design',
        title: 'Responsive Design',
        icon: '/images/icon-responsive.png',
        short: 'In der digitalen Ära ist Flexibilität entscheidend. Ihr Auftritt ist über verschiedene Geräte und Bildschirmgrößen erreichbar.'
    },
    {
        img: '/images/logo-mittel.jpg',
        url: '/services/logo-design',
        title: 'Logo Design',
        icon: '/images/icon-logo.png',
        short: 'Ein Logo, das nicht nur auffällt, sondern auch Ihre Einzigartigkeit unterstreicht.'
    },
    {
        img: '/images/seo-mittel.jpg',
        url: '/services/seo-optimierung',
        title: 'SEO Optimierung',
        icon: '/images/icon-seo.png',
        short: 'Ihre Website an die Spitze der Suchergebnisse bringen, damit Sie von potenziellen Kunden entdeckt werden.'
    },
    {
        img: '/images/webhosting-mittel.jpg',
        url: '/services/webhosting',
        title: 'Webhosting',
        icon: '/images/icon-webhosting.png',
        short: 'Webhosting ist mehr als nur ein Speicherplatz, es ist die Grundlage für Ihre Online-Präsenz.'
    }
];

export const experience = [
    {
        title: '20 Jahre Expertise',
        text: 'Mit stolzen 20 Jahren Berufserfahrung verstehen wir die Feinheiten der digitalen Landschaft. Diese langjährige Expertise bildet das Fundament, auf dem wir innovative Lösungen gestalten und bewährte Praktiken nutzen, um Ihren Anforderungen gerecht zu werden.'
    },
    {
        title: 'Vielseitige Kompetenzen',
        text: 'Unser Team vereint Fachkenntnisse in Webdesign, Webentwicklung, Responsive Design, Logo Design, SEO-Optimierung und Webhosting. Diese breite Palette von Fähigkeiten ermöglicht es uns, umfassende Lösungen anzubieten, die den gesamten digitalen Lebenszyklus abdecken.'
    },
    {
        title: 'Individuelle Kundenbetreuung',
        text: 'Wir verstehen, dass jeder Kunde einzigartig ist. Daher legen wir großen Wert darauf, auf Ihre spezifischen Anforderungen einzugehen. Unser Team nimmt sich die Zeit, Ihre Vision zu verstehen und maßgeschneiderte Lösungen zu entwickeln, die genau auf Sie zugeschnitten sind.'
    },
    {
        title: 'Leidenschaft für Excellenz',
        text: 'Bei Mindresult sind wir leidenschaftlich darauf bedacht, nicht nur Ergebnisse zu liefern, sondern Exzellenz in jedem Projekt zu erreichen. Unser Team setzt sich dafür ein, Ihre Erwartungen zu übertreffen und beeindruckende digitale Erlebnisse zu schaffen.'
    },
    {
        title: 'Kundenorientierter Ansatz',
        text: 'Wir sehen unsere Kunden nicht nur als Auftraggeber, sondern als Partner. Ihr Erfolg ist unser Erfolg. Daher gehen wir über die bloße Dienstleistung hinaus und streben nach langfristigen Partnerschaften, die auf Vertrauen und gegenseitigem Erfolg basieren.'
    },
];
