import {useEffect} from "react";
import {servShowcaseHover} from "../../common/utilits";
import {Link} from "react-router-dom";
import {serviceList} from "../../common/staticTexte";

const WelcomeTextSection = () => {
    useEffect(() => {
        servShowcaseHover();
    }, []);

    const onClickEffect = () => {
        window.scroll({top: 0, left: 0, behavior: "smooth"});
    };

    return (
        <>
            {/* Mindresult Services */}
            <section className="mindresult-section mindresult-section-bg gap-top-100 gap-bottom-140">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">

                            {/* Heading */}
                            <div className="mindresult-heading gap-bottom-60">
                                <div className="mindresult-subtitle-1">
                                    <span style={{color: '#ff0802'}}>Willkommen bei</span>
                                </div>
                                <h2 className="mindresult-title-2">
                                    <img className="logo-shadow-black" src="/images/mindresult.png" alt="Mindresult"/>
                                </h2>
                                <div className="mindresult-text">
                                    <p>
                                        Bei uns erleben Sie nicht nur exzellente Gestaltung, sondern ein ganzheitliches
                                        Servicepaket, das keine Wünsche offen lässt.
                                        <br/>
                                        <br/>
                                        Unser Ansatz: Alles aus einer Hand! Von modernsten, technisch anspruchsvollen
                                        Webseiten bis hin zu lizenzierten Bildern
                                        und Videos - alles ist bereits im Preis enthalten. Wir verstehen, dass Ihre
                                        Online-Präsenz mehr ist als nur ein hübsches
                                        Design. Deshalb bieten wir Ihnen nicht nur die ästhetische Hülle, sondern auch
                                        den inneren Wert.
                                        <br/>
                                        <br/>
                                        Unsere Texte sind keine Standard-Phrasen. Wir haben für Sie erfahrene Autoren,
                                        die Inhalte schaffen, die nicht nur
                                        informieren, sondern begeistern. Zusätzlich kümmern sich unsere Anwälte um die
                                        rechtliche Sicherheit Ihrer Webseite.
                                        Maßgeschneiderte AGB und Datenschutzbestimmungen, die speziell für Ihr
                                        Unternehmen angepasst werden, geben Ihnen
                                        die Gewissheit, rechtlich auf der sicheren Seite zu stehen.
                                        <br/>
                                        <br/>
                                        Aber das ist noch nicht alles! Unsere Experten kümmern sich auch um die
                                        Suchmaschinenoptimierung (SEO) Ihrer Webseite.
                                        Damit werden Sie nicht nur online gefunden, sondern auch von potenziellen Kunden
                                        wahrgenommen. Wir machen Ihre
                                        Webseite sichtbar und bringen Sie in die Spitzenpositionen der Suchergebnisse.
                                        <br/>
                                        <br/>
                                        Entdecken Sie mit Mindresult eine Welt des digitalen Erfolgs, in der Qualität,
                                        Service und Kundenzufriedenheit
                                        an erster Stelle stehen. Lassen Sie uns gemeinsam Ihre Vision in eine
                                        beeindruckende Online-Realität verwandeln – alles
                                        aus einer Hand, alles für Ihren Erfolg!
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 offset-lg-1 hide-on-mobile">

                            {/* Image */}
                            <img className="logo-shadow-black" src="/images/logo.png" alt="logo"/>

                        </div>
                    </div>

                    {/* Services Showcase */}
                    <div className="mindresult-services-showcase">
                        <div className="row">
                            <div className="col-xs-0 col-sm-0 col-md-0 col-lg-7"></div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                                <div className="mindresult-subtitle-1">
                                    <span>Was bieten wir?</span>
                                </div>

                                {/*items*/}
                                <div className="items">
                                    <span className="img-circle mindresult-circle-move"/>
                                    <ul>
                                        {serviceList.map((item, key) => (
                                            <li key={`services3-item-${key}`}>
                                                <div className="image">
                                                <span className="img" style={{
                                                    "backgroundImage": "url(" + item.img + ")",
                                                    borderRadius: '10%'
                                                }}/>
                                                </div>
                                                <h5 className="title">
                                                    <Link className="mindresult-no-text-decoration"
                                                          to={item.url} onClick={onClickEffect}>
                                                <span className="num">
                                                    <span data-splitting data-mindresult-scroll> {key + 1}. </span>
                                                </span>
                                                        <span className="name">
                                                    <span className="mindresult-lnk-black lnk--active">
                                                        <span data-splitting data-mindresult-scroll>{item.title}</span>
                                                    </span>
                                                </span>
                                                    </Link>
                                                </h5>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {/* Button */}
                                <Link className="mindresult-btn mindresult-hover-btn" to="/services">
                                    <i className="arrow"><span/></i>
                                    <span>Unsere Leistungen</span>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
};

export default WelcomeTextSection;
